import React from 'react';
import {ProductThumbnail} from '@wix/wixstores-client-common-components';
import s from './CartItemThumbnail.scss';
import {ItemLink} from '../CartItem/partials/ItemLink/ItemLink';
import {getImageDimensions} from '../../../../domain/utils/itemUtilsWithSideEffect';
import classNames from 'classnames';
import {LineItemModel} from '../../../../domain/models/LineItem.model';

export enum CartItemThumbnailDataHook {
  Image = 'CartItemThumbnailDataHook.Image',
}

export const CartItemThumbnail = ({lineItem, className}: {lineItem: LineItemModel; className?: string}) => {
  const media = lineItem.image ? [{...lineItem.image, mediaType: 'PHOTO'}] : [];
  const product = {
    productType: lineItem.itemType,
    name: lineItem.productName,
    media,
  };

  return (
    <div className={classNames(s.thumbnail, className)} data-hook={CartItemThumbnailDataHook.Image}>
      <ItemLink lineItem={lineItem} tabIndex={-1}>
        <ProductThumbnail
          product={product}
          {...getImageDimensions(lineItem, CartItemThumbnail.defaultDimensions)}
          mountedToDOM={true}
        />
      </ItemLink>
    </div>
  );
};

CartItemThumbnail.defaultDimensions = {width: 100, height: 100};
