import React from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import {HTMLTag} from '../HTMLTag/HTMLTag';
import s from './OrderSummary.scss';
import {SubTotals} from './SubTotals/SubTotals';
import {CouponTotal} from './CouponTotal/CouponTotal';
import {Total} from './Total/Total';
import {PayNowLater} from './PayNowLater/PayNowLater';
import cx from 'classnames';
import {Spinner, SpinnerTypes} from 'wix-ui-tpa';
import {Violations} from '../../../../../common/components/Violations/Violations';
import {MinimumOrderAmount} from '../MinimumOrderAmount/MinimumOrderAmount';
import {CheckoutButtons} from '../CheckoutButtons/CheckoutButtons';
import {SecureCheckout} from '../../../../../common/components/SecureCheckout/SecureCheckout';
import {CartBodyDataHook} from '../../CartApp/partials/CartBody';
import {useIsAfterHydration} from '../../../../../hooks/useIsAfterHydration';
import {CartSummarySkeleton} from './CartSummarySkeleton/CartSummarySkeleton';
import {EstimateTotalsErrorNotification} from './EstimateTotalsErrorNotification/EstimateTotalsErrorNotification';

export enum OrderSummaryDataHook {
  OrderSummaryHeadline = 'OrderSummary.headline',
  SubTotalsRoot = 'SubTotals.root',
  SubTotalsValue = 'SubTotals.subtotalText',
  EstimatedTaxRoot = 'EstimatedTax.root',
  EstimatedTaxShippingDestinationWithTitle = 'EstimatedTax.shippingDestinationWithTitle',
  EstimatedTaxTaxName = 'EstimatedTax.taxName',
  EstimatedTaxEstimatedTaxPrice = 'EstimatedTax.estimatedTaxPrice',
  TotalShippingRoot = 'TotalShipping.root',
  TotalShippingShippingDestinationWithTitle = 'TotalShipping.shippingDestinationWithTitle',
  TotalShippingTitle = 'TotalShipping.title',
  TotalShippingEstimatedShipping = 'TotalShipping.estimatedShipping',
  ShippingDestinationRoot = 'ShippingDestination.shippingDestination',
  ShippingDestinationTaxRoot = 'ShippingDestination.taxShippingDestination',
  ShippingDestinationChangeRegion = 'ShippingDestination.changeRegion',
  DeliveryMethodRoot = 'DeliveryMethod.root',
  DeliveryMethodDropdown = 'DeliveryMethod.dropdown',
  DeliveryMethodDropdownDescription = 'DeliveryMethod.dropdown-description',
  DeliveryErrorRoot = 'DeliveryError.root',
  DeliveryErrorMessage = 'DeliveryError.error',
  DeliveryNotificationRoot = 'DeliveryNotification.root',
  DeliveryNotificationMessage = 'DeliveryNotification.message',
  CouponRoot = 'Coupon.root',
  CouponCode = 'Coupon.code',
  CouponLabel = 'Coupon.label',
  CouponDiscountValue = 'Coupon.discountValue',
  TotalTitle = 'Total.title',
  TotalFormattedValue = 'Total.formattedValue',
  TotalCurrencyConverterDisclaimer = 'Total.currencyConverterDisclaimer',
  TotalTaxIncludedLabel = 'Total.taxIncludedLabel',
  TotalCartDisclaimer = 'Total.cartDisclaimer',
  SingleAdditionalFeeRoot = 'SingleAdditionalFee.root',
  MultipleAdditionalFeesRoot = 'MultipleAdditionalFees.root',
  AdditionalFeesTitle = 'AdditionalFees.title',
  AdditionalFeesName = 'AdditionalFees.name',
  AdditionalFeesPrice = 'AdditionalFees.price',
  PayNowLaterRoot = 'PayNowLater.root',
  PayNowTitle = 'PayNowLater.payNowTitle',
  PayNowValue = 'Total.payNowValue',
  PayLaterTitle = 'PayLaterLater.payLaterTitle',
  PayLaterValue = 'Total.payLaterValue',
  Loader = 'OrderSummary.Loader',
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const OrderSummary = () => {
  const {t} = useTranslation();
  const {shouldDisplaySubtotal, shouldDisplaySubtotalTitle, shouldDisplayPayNowPayLater} =
    useControllerProps().orderStore;
  const {
    estimatedTotals,
    cartModel,
    isSummaryUpdating,
    shouldShowSecureCheckout,
    shouldDisplayViolations,
    shouldShowMinimumOrderAmount,
    shouldEstimateTotals,
    isSummaryLoading,
    mapCartSummaryColors,
    errorOnEstimateTotals,
  } = useControllerProps().cartStore;
  const appliedCoupon = estimatedTotals?.appliedCoupon || cartModel.appliedCoupon;
  const classNames = cx(s.headline, {
    [s.withSubtotalTitle]: shouldDisplaySubtotalTitle,
  });

  const isAfterHydration = useIsAfterHydration();

  const showAsideLoader = isSummaryLoading || (!isAfterHydration && shouldEstimateTotals);

  const summaryContent = () => {
    if (errorOnEstimateTotals) {
      return <EstimateTotalsErrorNotification />;
    }
    if (showAsideLoader) {
      return <CartSummarySkeleton />;
    }

    return (
      <div
        data-hook={CartBodyDataHook.CartAsideContent}
        className={mapCartSummaryColors ? s.cartSummary : /* istanbul ignore next */ undefined}>
        {isSummaryUpdating && <Spinner data-hook={OrderSummaryDataHook.Loader} isCentered type={SpinnerTypes.slim} />}
        <div className={isSummaryUpdating ? s.loading : undefined}>
          {shouldDisplaySubtotal && <SubTotals />}
          {appliedCoupon && <CouponTotal />}
          <Total />
          {shouldDisplayPayNowPayLater && <PayNowLater />}
        </div>
        {shouldDisplayViolations && <Violations />}
        {shouldShowMinimumOrderAmount && <MinimumOrderAmount />}
        <CheckoutButtons />
        {shouldShowSecureCheckout && <SecureCheckout text={t('cart.orderSummary.secureCheckout.label')} />}
      </div>
    );
  };

  return (
    <div className={s.totals}>
      <HTMLTag type="h2" className={classNames} data-hook={OrderSummaryDataHook.OrderSummaryHeadline}>
        {t('cart.orderSummary.title')}
      </HTMLTag>
      {summaryContent()}
    </div>
  );
};
