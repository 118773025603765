import React from 'react';
import {ButtonPriority, SectionNotification, SectionNotificationType} from 'wix-ui-tpa';
import Alert from 'wix-ui-icons-common/on-stage/Alert';
import s from './EstimateTotalsErrorNotification.scss';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {Refresh} from 'wix-ui-icons-common';
import {useControllerProps} from '../../../../../../domain/controllers/ControllerContext';

export enum EstimateTotalsErrorNotificationDataHook {
  Root = 'EstimateTotalsErrorNotificationDataHook.Root',
}

export const EstimateTotalsErrorNotification = () => {
  const {t} = useTranslation();
  const {fetchEstimateTotals} = useControllerProps().cartStore;

  return (
    <SectionNotification
      className={s.notification}
      data-hook={EstimateTotalsErrorNotificationDataHook.Root}
      type={SectionNotificationType.alert}>
      <SectionNotification.Icon icon={<Alert className={s.icon} />} />
      <SectionNotification.Text>{t('cart.estimateTotals.error.text')}</SectionNotification.Text>
      <SectionNotification.Button
        key="secondary"
        priority={ButtonPriority.basicSecondary}
        onClick={() => fetchEstimateTotals()}>
        <div className={s.buttonContent}>
          <SectionNotification.Icon icon={<Refresh />} />
          {t('cart.estimateTotals.error.cta')}
        </div>
      </SectionNotification.Button>
    </SectionNotification>
  );
};
